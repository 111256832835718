import { Share } from "@capacitor/share";
import { BaseLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { type ReferralBonus, ReferralBonusTypes } from "../types";

interface ShareReferralCodeFooterProps {
  referralBonus: ReferralBonus;
  selectedLicenseId: string;
  referralCode: string;
}
export function ShareReferralCodeFooter(props: ShareReferralCodeFooterProps) {
  const { referralBonus, selectedLicenseId, referralCode } = props;

  const { showErrorToast } = useToast();
  const { attributes: { name: msaName, program: bonusType, compensationPerLicense } = {} } =
    referralBonus;
  const bonusAmountInUsd = compensationPerLicense?.[selectedLicenseId];
  const referralBonusIsSignUp = bonusType === ReferralBonusTypes.SIGN_UP;

  return (
    <Stack spacing={1} alignItems="center">
      <Button
        fullWidth
        variant="contained"
        onClick={async () => {
          if (referralCode.length <= 0 || !isDefined(bonusAmountInUsd)) {
            showErrorToast("There was an error sharing your referral code, please try again.");
            return;
          }

          try {
            const sharingContent = {
              dialogTitle: "Invite to join Clipboard Health",
              title: referralBonusIsSignUp
                ? `Download the Clipboard Health app for a $${bonusAmountInUsd} bonus!`
                : `Check out Clipboard Health for a $${bonusAmountInUsd} bonus!`,
              text: referralBonusIsSignUp
                ? `Hey! Clipboard Health is the nursing marketplace I was telling you about - they are urgently looking for ${selectedLicenseId} ${
                    msaName ? `in ${msaName}` : ""
                  }. They will pay us both a $${bonusAmountInUsd} bonus after you enroll. The offer expires tomorrow but it takes just 5 minutes and they pay instantly to any debit card or bank account. This is the code you need to enter: ${referralCode}`
                : `Hey! I work through Clipboard Health, and I love it! Use my referral code ${referralCode} after signing up. When your first shift is verified, we'll BOTH get a $${bonusAmountInUsd} bonus. 😊`,
              url: `https://cbh.care/welcome`,
            };
            if (isCapacitorPlatform()) {
              await Share.share(sharingContent);
              logEvent(APP_V2_USER_EVENTS.SHARED_CODE_FROM_WORKER_TO_WORKER_REFERRAL_SCREEN, {
                bonusType,
                msaName,
                bonusAmountInUsd,
                referralCode,
              });
              return;
            }

            const mailtoLink = `mailto:?subject=${sharingContent.title}&body=${sharingContent.text}\n ${sharingContent.url}`;
            window.open(mailtoLink, "_blank", "noopener");
            logEvent(APP_V2_USER_EVENTS.SHARED_CODE_FROM_WORKER_TO_WORKER_REFERRAL_SCREEN, {
              bonusType,
              msaName,
              bonusAmountInUsd,
              referralCode,
            });
          } catch (error) {
            const { message: errorMessage = "Unknown error" } = error instanceof Error ? error : {};
            // When user closes the native share dialog manually, the share api throws an error
            logEvent(APP_V2_APP_EVENTS.SHARE_REFERRAL_CODE_ERROR, {
              referralCode,
              bonusAmountInUsd,
              msaName,
              bonusType,
              errorMessage,
            });
          }
        }}
      >
        Share referral code
      </Button>

      <Text>
        Or
        <Button
          to={DeprecatedGlobalAppV1Paths.CO_WORKER_REFERRAL}
          component={BaseLink}
          onClick={() => {
            logEvent(
              APP_V2_USER_EVENTS.VISITED_ENTER_REFERRAL_CODE_FROM_WORKER_TO_WORKER_REFERRAL_SCREEN
            );
          }}
        >
          Enter referral code
        </Button>
      </Text>
    </Stack>
  );
}
