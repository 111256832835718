import { Box, Stack } from "@mui/material";
import {
  GRAY_C0,
  PRIMARY_HIGHLIGHT_COLORS,
  SECONDARY_HIGHLIGHT_COLORS,
} from "@src/appV2/lib/Theme/constants";
import { type ReactNode } from "react";

type ReferralBonusCardVariant = "primary" | "secondary" | "default";

const LIGHTER_SECONDARY_HIGHLIGHT_COLOR = "#CEECFF";

const TITLE_BACKGROUND_COLORS: Record<ReferralBonusCardVariant, string> = {
  primary: PRIMARY_HIGHLIGHT_COLORS.color,
  secondary: LIGHTER_SECONDARY_HIGHLIGHT_COLOR,
  default: GRAY_C0,
};

const CONTENT_BACKGROUND_COLORS: Record<ReferralBonusCardVariant, string> = {
  primary: PRIMARY_HIGHLIGHT_COLORS.backgroundColor,
  secondary: SECONDARY_HIGHLIGHT_COLORS.backgroundColor,
  default: "inherit",
};

const TITLE_HEIGHT_IN_PIXELS = 40 as const;

export interface ReferralBonusCardProps {
  title: ReactNode;
  subtitle?: ReactNode;
  mode?: ReferralBonusCardVariant;
  children: ReactNode;
}

export function ReferralBonusCard(props: ReferralBonusCardProps) {
  const { title, subtitle = "", mode = "default", children } = props;

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ position: "relative", paddingTop: `${TITLE_HEIGHT_IN_PIXELS}px` }}
    >
      <Stack
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        paddingX={3}
        paddingY={2}
        height={TITLE_HEIGHT_IN_PIXELS * 2}
        sx={{
          position: "absolute",
          top: 0,
          margin: "auto",
          backgroundColor: TITLE_BACKGROUND_COLORS[mode],
          borderRadius: `${TITLE_HEIGHT_IN_PIXELS}px`,
        }}
      >
        {title}
        {subtitle}
      </Stack>
      <Box
        padding={2}
        paddingTop={`${TITLE_HEIGHT_IN_PIXELS + 16}px`}
        sx={{
          backgroundColor: CONTENT_BACKGROUND_COLORS[mode],
          borderColor: TITLE_BACKGROUND_COLORS[mode],
          borderStyle: "solid",
          borderRadius: 2,
          borderWidth: 2,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}
