import { isDefined } from "@clipboard-health/util-ts";

import { type ReferralBonus } from "../types";

interface GetSelectedMsaOptionOptions {
  referralBonuses: ReferralBonus[];
  msaOptionValue?: ReferralBonus;
  preferredMsa?: string;
}

export function getSelectedMsaOption(
  options: GetSelectedMsaOptionOptions
): ReferralBonus | undefined {
  const { referralBonuses, msaOptionValue, preferredMsa } = options;
  if (isDefined(msaOptionValue) && msaOptionValue?.id !== "") {
    return msaOptionValue;
  }

  const preferredMsaOption = referralBonuses.find(
    (referralBonus) => referralBonus.attributes.name === preferredMsa
  );
  return preferredMsaOption ?? referralBonuses[0];
}
