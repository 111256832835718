export function getModifiedMsaName(name: string, delimiter = "-") {
  if (name === "") {
    return "";
  }

  const msaNameAndState = name.split(",") ?? [];
  if (msaNameAndState.length < 2) {
    return name;
  }

  return `${msaNameAndState[1]?.trim() ?? ""} ${delimiter} ${msaNameAndState[0]?.trim() ?? ""}`;
}
