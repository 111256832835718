import { useGetQuery } from "@src/appV2/api/useGetQuery";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { GET_WORKER_FRIEND_LINK_PATH } from "./constants";

const WorkerProfileLinkResponseSchema = z.object({
  url: z.string(),
});

export type WorkerProfileLinkResponse = z.infer<typeof WorkerProfileLinkResponseSchema>;

export function useWorkerFriendLink(): UseQueryResult<WorkerProfileLinkResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_WORKER_FRIEND_LINK_PATH}`,
    responseSchema: WorkerProfileLinkResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while retrieving your link to share with friends",
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_REFERRAL_STATS_FAILURE,
    },
  });
}
