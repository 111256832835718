import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import { type ReferralBonusesResponse, referralBonusesResponseSchema } from "../types";

export const GET_REFERRAL_BONUSES_PATH = "/referral-bonuses";

export function useReferralBonuses(
  options: UseGetQueryOptions<ReferralBonusesResponse> = {}
): UseQueryResult<ReferralBonusesResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_REFERRAL_BONUSES_PATH}`,
    responseSchema: referralBonusesResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while fetching the referral bonuses",
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_REFERRAL_BONUSES_FAILURE,
    },
    ...options,
  });
}
