import { z } from "zod";

export enum ReferralBonusTypes {
  SIGN_UP = "sign-up",
  ACTIVATION = "activation",
}

export const referralBonusAttributeSchema = z.object({
  name: z.string(),
  program: z.nativeEnum(ReferralBonusTypes),
  compensationPerLicense: z.record(z.string(), z.number()),
});

export const referralBonusSchema = z.object({
  id: z.string(),
  type: z.string(),
  attributes: referralBonusAttributeSchema,
});

export const referralBonusesResponseSchema = z.object({
  data: z.array(referralBonusSchema),
});

export const referralProfilesAttributeSchema = z.object({
  preferredMsa: z.string(),
});

export const referralProfilesResponseSchema = z.object({
  data: z.object({
    attributes: referralProfilesAttributeSchema,
    id: z.string(),
    type: z.string(),
  }),
});
export const referralProfilesRequestSchema = z.object({
  workerId: z.string(),
});

export type ReferralBonusesResponse = z.infer<typeof referralBonusesResponseSchema>;
export type ReferralBonus = z.infer<typeof referralBonusSchema>;

export type ReferralProfileResponse = z.infer<typeof referralProfilesResponseSchema>;
export type ReferralProfile = z.infer<typeof referralProfilesAttributeSchema>;
export type ReferralProfileRequest = z.infer<typeof referralProfilesRequestSchema>;
