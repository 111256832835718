import { Image, Text } from "@clipboard-health/ui-react";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";
import { LinkCard } from "@src/appV2/lib/Links/LinkCard";

export interface WorkerReferralBannerProps {
  isFullHeight?: boolean;
}
export function WorkerReferralBanner(props: WorkerReferralBannerProps) {
  const { isFullHeight = false } = props;
  return (
    <LinkCard
      isFullHeight={isFullHeight}
      to={{
        pathname: DeprecatedGlobalAppV1Paths.REFERRAL_V2,
      }}
      title={
        <Text bold variant="body2">
          Earn $50 when you refer a friend
        </Text>
      }
      subtitle={<Text variant="caption">Tap here to start earning</Text>}
      variant="promotion"
      leadingIcon={<Image src={cbhBunnyLogo} alt="" width={40} height={40} />}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.CLICKED_ON_WORKER_TO_WORKER_REFERRAL_BANNER);
      }}
    />
  );
}
