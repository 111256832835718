import { Span, Text, Title } from "@clipboard-health/ui-react";
import BoltIcon from "@mui/icons-material/Bolt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { List, ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material";

import { ReferralBonusTypes } from "../types";
import { ReferralBonusCard } from "./ReferralBonusCard";

export interface ActiveReferralBonusProps {
  bonusAmountInUsd: number;
  bonusType: ReferralBonusTypes;
}

export function ActiveReferralBonus(props: ActiveReferralBonusProps) {
  const { bonusType, bonusAmountInUsd } = props;
  const referralBonusIsSignUp = bonusType === ReferralBonusTypes.SIGN_UP;
  return (
    <ReferralBonusCard
      title={
        <Title bold component="h2">
          Earn ${bonusAmountInUsd}
        </Title>
      }
      subtitle={
        referralBonusIsSignUp ? (
          <Stack direction="row" alignItems="center" justifyContent="space-around">
            <BoltIcon fontSize="small" />
            <Text variant="subtitle1">Instantly</Text>
          </Stack>
        ) : (
          ""
        )
      }
      mode={bonusType === ReferralBonusTypes.SIGN_UP ? "primary" : "secondary"}
    >
      <Stack direction="column">
        <Text bold>You get</Text>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              {referralBonusIsSignUp ? (
                <Text>${bonusAmountInUsd} when they enter your referral code and enroll.</Text>
              ) : (
                <Text>
                  ${bonusAmountInUsd}{" "}
                  <Span sx={{ fontWeight: "bold" }}>when they work their first shift.</Span>
                </Text>
              )}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              {referralBonusIsSignUp ? (
                <Text>
                  Bonus <Span sx={{ fontWeight: "bold" }}>immediately available</Span>, no need to
                  do anything else!
                </Text>
              ) : (
                <Text>Bonus is deposited after the shift is verified.</Text>
              )}
            </ListItemText>
          </ListItem>
        </List>
        <Text bold variant="body1">
          They get
        </Text>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              {referralBonusIsSignUp ? (
                <Text>${bonusAmountInUsd} when they enter your code during sign-up.</Text>
              ) : (
                <Text>
                  ${bonusAmountInUsd}{" "}
                  <Span sx={{ fontWeight: "bold" }}>when they work their first shift.</Span>
                </Text>
              )}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              {referralBonusIsSignUp ? (
                <Text>To receive the bonus, they must be enrolled and Stripe must be enabled.</Text>
              ) : (
                <Text> Make sure they enter code before their first shift.</Text>
              )}
            </ListItemText>
          </ListItem>
        </List>
      </Stack>
    </ReferralBonusCard>
  );
}
