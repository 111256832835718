import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { LeftRightItemsAutocomplete } from "@src/appV2/lib/LeftRightItemsAutocomplete";
import {
  PRIMARY_HIGHLIGHT_COLORS,
  SECONDARY_HIGHLIGHT_COLORS,
} from "@src/appV2/lib/Theme/constants";
import { sortByDefinedOrder } from "@src/appV2/lib/utils/sortByDefinedOrder";

import { LICENSES_WITH_MOST_SHIFTS } from "../constants";
import { type ReferralBonus, ReferralBonusTypes } from "../types";
import { getMsaOptionItem } from "../utils/getMsaOptionItem";

interface ReferralBonusSelectorProps {
  referralBonuses: ReferralBonus[];
  selectedMsaOption?: ReferralBonus;
  selectedLicenseId: string;
  setMsaValueId: (id: string) => void;
  setLicenseValueId: (id: string) => void;
}

export function ReferralBonusSelector(props: ReferralBonusSelectorProps) {
  const {
    referralBonuses,
    selectedLicenseId,
    selectedMsaOption,
    setMsaValueId,
    setLicenseValueId,
  } = props;

  const { compensationPerLicense, program } = selectedMsaOption?.attributes ?? {};

  const licenseOptions = sortByDefinedOrder({
    items: Object.keys(compensationPerLicense ?? {}),
    definedOrder: LICENSES_WITH_MOST_SHIFTS,
  });

  return (
    <Box
      paddingY={2}
      paddingX={1}
      sx={{
        border: () =>
          program === ReferralBonusTypes.ACTIVATION
            ? SECONDARY_HIGHLIGHT_COLORS.color
            : PRIMARY_HIGHLIGHT_COLORS.color,
        borderRadius: 1,
        backgroundColor:
          program === ReferralBonusTypes.ACTIVATION
            ? SECONDARY_HIGHLIGHT_COLORS.backgroundColor
            : PRIMARY_HIGHLIGHT_COLORS.backgroundColor,
      }}
    >
      <Text>When referring friends in</Text>
      <Stack spacing={1}>
        <LeftRightItemsAutocomplete
          disableClearable
          blurOnSelect
          value={
            isDefined(selectedMsaOption)
              ? getMsaOptionItem(selectedMsaOption)
              : { id: "", label: "", leftItem: "" }
          }
          options={referralBonuses.map((referralBonus) => getMsaOptionItem(referralBonus))}
          sx={{ flex: 2 }}
          onChange={(_, newValue) => {
            const selectedMsa = referralBonuses.find((option) => option.id === newValue.id);
            if (!isDefined(selectedMsa)) {
              return;
            }

            setMsaValueId(selectedMsa.id);
          }}
        />

        <Stack direction="row" spacing={1} alignItems="center">
          <Text>with qualification</Text>
          <LeftRightItemsAutocomplete
            disableClearable
            blurOnSelect
            value={{
              id: selectedLicenseId,
              label: selectedLicenseId,
              leftItem: selectedLicenseId,
            }}
            options={licenseOptions.map((license) => ({
              id: license,
              label: license,
              leftItem: license,
            }))}
            sx={{ flex: 1 }}
            onChange={(_, newValue) => {
              setLicenseValueId(newValue.id);
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
