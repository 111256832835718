import { type UseModalState } from "@clipboard-health/ui-react";
import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs";
import { SupportArticleLinks } from "@src/appV2/support/constants";

export interface LocationVerificationFailedProps {
  modalState: UseModalState;
  resetLocationVerification: () => void;
}
export function LocationVerificationFailed(props: LocationVerificationFailedProps) {
  const { modalState, resetLocationVerification } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Location Verification Failed"
      actions={
        <Button
          fullWidth
          variant="outlined"
          onClick={async () => {
            modalState.closeModal();
            resetLocationVerification();
          }}
        >
          Maybe later
        </Button>
      }
    >
      <Text>
        We are unable to pay your sign-up bonus because your location does not match the address we
        have on file for you. To update your address, go to the &quot;My Account&quot; section of
        the app and tap &quot;Address.&quot; Once you&apos;ve edited your address to your location
        you can apply the referral code again. Learn more{" "}
        <ExternalLink to={SupportArticleLinks.REFERRAL_BONUS}>here</ExternalLink>.
      </Text>
    </CommonDialog>
  );
}
