import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import {
  type ReferralProfileRequest,
  type ReferralProfileResponse,
  referralProfilesResponseSchema,
} from "../types";

export const GET_REFERRAL_PROFILE_PATH = "/referral-profiles";

export function useReferralProfiles(
  queryParams: ReferralProfileRequest,
  options: UseGetQueryOptions<ReferralProfileResponse> = {}
): UseQueryResult<ReferralProfileResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_REFERRAL_PROFILE_PATH}`,
    responseSchema: referralProfilesResponseSchema,
    queryParams,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_REFERRAL_PROFILE_FAILURE,
    },
    ...options,
  });
}
