import { ProgressDialog } from "@src/appV2/lib/Dialogs";

export interface LocationVerificationInProgressDialogProps {
  isLoading: boolean;
}
export function LocationVerificationInProgressDialog(
  props: LocationVerificationInProgressDialogProps
) {
  const { isLoading } = props;

  return (
    <ProgressDialog modalIsOpen={isLoading} messageContent="Verifying location, please wait." />
  );
}
