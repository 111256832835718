import { type AutocompleteOptionItem } from "@src/appV2/lib/LeftRightItemsAutocomplete";

import { type ReferralBonus } from "../types";
import { getModifiedMsaName } from "./getModifiedMsaName";

export function getMsaOptionItem(msaReferralBonus: ReferralBonus): AutocompleteOptionItem {
  const {
    id,
    attributes: { name: originalMsaName },
  } = msaReferralBonus;

  const modifiedMsaName = getModifiedMsaName(originalMsaName);

  return {
    id,
    label: modifiedMsaName,
    leftItem: modifiedMsaName,
  };
}
