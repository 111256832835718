import { type UseModalState } from "@clipboard-health/ui-react";
import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs";
import { SupportArticleLinks } from "@src/appV2/support/constants";

export interface LocationPermissionDialogProps {
  modalState: UseModalState;
  onContinue: () => Promise<void>;
}
export function LocationPermissionDialog(props: LocationPermissionDialogProps) {
  const { modalState, onContinue } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Enable location check"
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            onClick={async () => {
              modalState.closeModal();
              await onContinue();
            }}
          >
            Continue
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={async () => {
              modalState.closeModal();
            }}
          >
            Maybe later
          </Button>
        </>
      }
    >
      <Text>
        Please share your location so we can process your sign-up bonus. You can see the locations
        we currently offer a bonus in{" "}
        <ExternalLink to={SupportArticleLinks.REFERRAL_BONUS}>here</ExternalLink>.
      </Text>
    </CommonDialog>
  );
}
